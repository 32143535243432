@import '../../../css/general_styling';

.HelpPageContainer {
  height: 100%;
  width: 100%;
  background-color: #f2f2f2;
}

.TitleSection {
  display: flex;
}

.InfoSection {
  display: flex;
  padding: 1em 2em 1em 2em;
}

.FAQSection {
  display: flex;
  padding: 1em 2em 1em 2em;
}

.ContentSection {
  margin: 2em;
}

.text {
  display: flex;
  justify-content: flex-end;
  flex-grow: 0;
  Button {
    padding: 0 !important;
  }
}

.filler {
  height: 100%;
}
