@import '../../../css/general_styling';

.BlankSectionContainer {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;

  .Title {
  }
  .Children {
  }
}

.card {
  margin: 0em 2em 0em 2em;
  padding: 2em;
}

.highlightBorder {
  .Children {
    padding-left: 1em;
    border-left: 2px solid $primary-color;
  }
}
