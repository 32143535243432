@import '../../../css/general_styling';

.FaqPageContainer {
  height: 100%;
  width: 100%;
}

.faqContent {
  padding: 2vh;
}
