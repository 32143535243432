@import '../../../css/general_styling';

.Title {
  color: black;
  font-size: 1.5em;
}

.ManageUsersHeader {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.addUserForm {
  margin-top: 6vh;
}
