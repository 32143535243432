@import '../../../css/general_styling';

.menu {
  height: 92.5vh;
  width: 15vw;
  float: left;
  display: flex;
  flex-direction: column;
  .ProfileContainer {
    margin-top: 5vh;
    margin-bottom: 2vh;
  }
  .NavMenuContainer {
    height: 100%;
  }
  .SettingsContainer {
    justify-self: flex-end;
    margin-top: auto;
    margin-bottom: 4vh;
  }
}

@media only screen and (max-width: $min-desktop-screen-width-size) {
  .menu {
    height: 15vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    .NavMenuContainer {
      width: 100%;
    }
    .SettingsContainer {
      justify-self: flex-end;
      margin-top: auto;
      margin-bottom: 4vh;
    }
  }
}
