@import '../../css/general_styling';

.bodyContainer {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: $min-desktop-screen-width-size) {
  .bodyContainer {
    display: flex;
    flex-direction: column;
  }
}
