@import '../../../css/general_styling';

.small {
  font-size: x-small !important;
  text-align: left !important;
}

.videoIcon {
  margin-right: 2vh;
}

.supportedSection {
  margin-left: 2vh;
  margin-top: 2vh;
}

.content {
  margin-top: 2vh !important;
}
