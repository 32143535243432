@import '../../../css/general_styling';

.LogoutForm {
  padding: 5em;
  margin: auto;
  text-align: center;
}
.center {
  display: inline-block;
}
.font {
  font-size: 1.5em;
}
