.MediaShareEditorContainer {
  width: 50%;
}

.MediaShareCopyrightReuseForm {
  margin-top: 10px;
}

.Copyright {
  font-size: small;
  margin-left: 53vh;
}

.Reuse {
  font-size: small;
  margin-left: 49vh;
}

.TitleEmbed {
  font-size: 1em;
}

.MediaShareEditorEmbedCodeField {
  margin-top: 10px;
}

.CopyButton {
  margin-top: 1px;
  display: flex;
  justify-content: flex-end;
}
