@import '../../../css/general_styling';

.Title {
  color: black;
  font-size: 1.5em;
}

.EditMediaStructureContainer {
  width: 70%;
}

.EditMediaStructureHeader {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.EditMediaStructureActions {
  padding-top: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.DeleteMediaStructureAction {
  margin-top: 1vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.TitleEmbed {
  font-size: 1em;
}

.MediaShareEditorEmbedCodeField {
  margin-top: 10px;
}

.CopyButton {
  margin-top: 1px;
  display: flex;
  justify-content: flex-end;
}
