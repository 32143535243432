@import '../../../css/general_styling';

.SiteEditorContainer {
  margin-top: 15px;
}

.SiteEditorFields {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-row-gap: 5px;
}

.InputError {
  color: red;
  grid-column-start: 2;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type='number'] {
  -moz-appearance: textfield;
}
