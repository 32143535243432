@import '../../../css/general_styling';

.HealthPageContainer {
  padding: 5%;
}

.title {
  color: black;
  font-size: 2em;
}

.service {
  font-size: 1.5em;
  margin-top: 2vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
