@import '../../../css/general_styling';

.LoginForm {
  padding: 5em;
  margin: auto;
  text-align: center;
}

.center {
  display: inline-block;
}
