@import '../../../css/general_styling';

.CollectionsPageContainer {
  height: 100%;
  min-height: 90vh;
  width: 100%;
  background-color: #f2f2f2;
}
.actionButton {
  background-color: #fff;
  color: $primary-color;
  border: none;
}
.actionButton:hover {
  background-color: #fafafa;
}
