@import '../../../css/general_styling';

.UploadPageLTIContainer {
  padding: 3%;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background-color: #f2f2f2;
}

.UploadPageTitle {
  color: black;
  font-size: 2.5em;
}

.MediaUploaderContainer {
  margin-left: auto;
  margin-right: auto;
  width: 60%;
}

.UploadPageTitleContainer {
  margin-left: auto;
  margin-right: auto;
  width: 60%;
}
