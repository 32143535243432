@import '../../../css/general_styling';

.Error404Page {
  height: 100vh;
}

.Error404PageContainer {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 25%;
  left: 20%;
  padding: 2em;
}

.ImageContainer {
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.TitleContainer {
  font-size: 64px;
}

.ContentContainer {
  width: 60%;
  display: flex;
  flex-direction: column;
}

.DescriptionContainer {
  padding-left: 5em;
  Button {
    padding: 0 !important;
  }
}
