@import '../../../css/general_styling';

.ViewSwitch {
  margin-left: auto;
  display: flex;
  justify-content: right;
  justify-items: center;
}

.TableContainer {
  margin-top: 2em;
}

.optionsContainer {
  display: inline-flex;
  justify-items: center;
  width: -webkit-fill-available;
}

.filtersContainer {
  width: 200px;
}

.filterForm .ant-btn-primary {
  border-radius: 2em;
}
