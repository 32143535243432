@import '../../../css/general_styling';

.header {
  width: 100%;
  height: 7.5vh;
  display: flex;
  flex-direction: row;
  background-color: #302c2c;
  align-items: center;
  justify-content: space-between;
}

.ASULogoButton {
  margin-left: 0.4em;
  background-color: transparent;
  border: transparent;
}

.HeaderTitle {
  color: aliceblue;
  font-size: 1.5em;
}

.SignOutButton {
  margin-right: 2em;
  background-color: transparent;
  border: black;
  font-size: 1.2em;
  color: aliceblue;
}
