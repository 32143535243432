@import '../../../css/general_styling';

.TitleImageContainer {
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  position: relative;

  img {
    width: 100%;
    height: auto;
    object-fit: fill;
  }

  .overlay {
    position: absolute;
    bottom: 0em;
    left: 8em;

    .Title {
      width: 100%;
      color: white;
      font-size: 50px;
    }
  }
}
