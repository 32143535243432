@import '../../../css/general_styling';

.actionButtons {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.actionButtonsContainer {
  display: flex;
  justify-content: space-between;
}

.TitleShared {
  font-size: 1em;
}

.deleteButton {
  margin-left: 1vh;
}

.ExpirationDateSection {
  margin-bottom: 1vh;
}

.expirationText {
  color: #a5a5a5;
  margin-top: 0.5em;
}
