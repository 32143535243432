@import '../../../../css/general_styling';

.DeleteActionContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  button {
    background-color: transparent;
    border: black;
    padding: 0;
    font-size: 14px;
  }
  div {
    color: red;
  }
}
