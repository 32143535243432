@import '../../../css/general_styling';

.SettingsPageContainer {
  padding: 5%;
  width: 100%;
  background-color: #f2f2f2;
}

.Title {
  color: black;
  font-size: 1.5em;
}

.text {
  display: flex;
  justify-content: flex-end;
  flex-grow: 0;
  Button {
    padding: 0 !important;
  }
}
