@import '../../../css/general_styling';

.Dragger {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.ant-upload-drag-icon {
  font-size: 1.2em;
}
