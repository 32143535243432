@import '../../../css/general_styling';

.MediaPlayerTitleContainer {
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  max-width: 60%;
}

.MediaPlayerFlex {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 60%;
  min-height: 50vh;
}

.MediaPlayer {
  flex-grow: 1;
}
