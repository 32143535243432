@import '../../../css/general_styling';

.MediaShareContainer {
  margin-top: 2vh;
}

.Title {
  color: black;
  font-size: 1.5em;
}

.shareWithUsersBody {
  width: 80%;
}

.shareWithUsersHeader {
  margin-top: 2vh;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.shareWithUser {
  margin-top: 2vh;
}

.tooltip {
  margin-right: 1vh;
  vertical-align: super !important;
}
