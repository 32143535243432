.CreateMediaStructureActions {
  padding-top: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Title {
  color: black;
  font-size: 1.5em;
}

.SiteAdder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2vh;
}

.SiteNameInput {
  max-width: 400px;
}

.CreateMediaStuctureContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 5vh;
}

.CreateMediaStuctureFrom {
  width: 70%;

  .ant-col-8 {
    width: 50%;
  }
}

.CreateMediaStructureActions {
  padding-top: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
