@import '../../../css/general_styling';

.sortableTable .ant-table .ant-table-tbody > tr:hover > td {
  color: $primary-color;

  .row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
  }

  .row-dragging td {
    padding: 16px;
  }

  .row-dragging .drag-visible {
    visibility: visible;
  }
}
