@import '../../../css/general_styling';

.ProfileInfo {
  display: flex;
  flex-direction: column;
  margin: 0em 4em 0em 2em;

  .nameInfo {
    font-size: 1.5em;
  }

  .ExtraInfo {
    margin-top: 2vh;
  }
}
