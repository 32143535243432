@import '../../../css/general_styling';

.uploadFormContianer {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
}

.copyrightSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.copyrightReuseSection {
  display: flex;
  flex-direction: column;
  width: 45%;
}

.acceptSection {
  text-align: justify;
  text-justify: inter-word;
  width: 45%;
}

.formSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.CopyrightUpload {
  font-size: smaller;
  margin-left: 6vh;
}

.ReuseUpload {
  font-size: smaller;
  margin-left: 2vh;
}
