@import '../../../css/general_styling';

.MediaFeedHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 4.6em;

  .TitleContainer {
  }
  .ActionsContainer {
  }
}
