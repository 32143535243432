@import '../../../css/general_styling';

.NavMenu {
  height: 100%;
}
.BottomItems {
  position: absolute;
  bottom: 0;
  width: 13vw;
}
.MenuItem {
  size: 6em;
}

.frontawesomeIcon {
  width: 1.25em;
}

@media only screen and (max-width: $min-desktop-screen-width-size) {
  .BottomItems {
  }
}
