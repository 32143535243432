@import '../../../../css/general_styling';

.ActionsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  .notShow {
    background-color: transparent;
    border: black;
    padding: 0;
    font-size: 14px;
  }
}

.primary {
  color: $primary-color;
}

.warning {
  color: yellow;
}

.danger {
  color: red;
}
