@import '../../../css/general_styling';

.createSiteFormBody {
  margin-top: 5vh;
}
.title {
  font-size: 1.5em;
}
.createSiteForm {
  margin-top: 2vh;
}
.SiteEditorFormBody {
  margin-top: 5vh;
}
.EditSiteForm {
  margin-top: 2vh;
}
